@import '../../variables.module.scss';

.teachersDecks {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fit, 471px);
  grid-gap: 20px 206px;
  margin: 0 auto;
  margin-bottom: 85px;
}

.teachersDecksMobile {
  display: none;
  margin-bottom: 40px;
}

.slide {
  width: 400px;
}

@media screen and (max-width: 1105px) {
  .teachersDecksMobile {
    display: block;
  }
  .teachersDecks {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .slide {
    width: calc(100vw - 50px);
  }
}

@media screen and (max-width: 1264px) {
  .teachersDecks {
    grid-template-columns: repeat(auto-fit, 387px);
    grid-gap: 20px 87px;
  }
}

@media screen and (max-width: $sm-max-width) {
  .teachersDecksMobile {
    overflow: visible;
  }
}
