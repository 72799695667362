@import '../../variables.module.scss';

.avatarSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #0FD4CB;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  position: fixed;
  top: 115px;
  z-index: 5;
}

.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: -26px;

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.avatarBox {
  position: relative;
  width: 110px;
}

.avatar {
  border-color: #67D29E;
  border: 1px;
}

.imageInput {
  display: none;
}

.userName {
  font-family: $title-font-family;
  font-size: 32px;
  color: #6F7575;
  display: flex;
  justify-content: center;
  margin-top: 120px;
  text-align: center;
}

.userLanguages {
  font-family: $normal-font-family;
  font-size: 16px;
  color: #0fd4cb;
  display: flex;
  font-weight: 500;
  text-align: center;
  flex-wrap: wrap;
  margin-top: 6px;
}

.deckListWrapper {
  padding: 20px 15px;
  padding-top: 0;
  display: flex;
  flex-flow: column;
  max-width: 1180px;
  margin: 0 auto;
}

.title {
  margin-top: 48px;
  margin-bottom: 18px;
  font-family: $normal-font-family;
  font-weight: 700;
  font-size: 16px;
  color: #16B5DF;
  text-transform: uppercase;
}

.zh {
  .title {
    font-weight: 500;
  }
  .recommendedCreator h4 {
    font-weight: 600;
  }
}

.locked {
  font-family: $normal-font-family;
  font-size: 14px;
  margin-left: 20px;
  text-transform: none;
  font-weight: normal;
  color: #6F7575;
}

.bio {
  padding: 20px 15px;
  display: flex;
  flex-flow: column;
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
}

.recommendedCreator {
  display: flex;
  align-items: baseline;
  h4 {
    display: block;
    margin-left: 6px;
    font-weight: bold;
    font-size: 14px;
    color: #2D65C2;
  }
}

.introduction {
  font-size: 14px;
  line-height: 147.5%;
  letter-spacing: 0.04em;
  color: #6F7575;
  margin-top: 5px;
  font-family: $normal-font-family;
  white-space: pre-wrap;
}

.keywords {
  display: flex;
}

.keyword {
  min-width: 110px;
  height: 30px;
  border: 1px solid #97ACD9;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  color: #6F7575;
  font-weight: 700;
  margin-right: 6px;
}

.friendsWrapper {
  display: flex;
  color: #3CC884;
  font-weight: bold;
  font-size: 14px;
  margin-top: 6px;
}

.friendsCount {
  color: #6F7575;
  margin-left: 10px;
}

.website {
  position: absolute;
  top: 16px;
  right: 16px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.self {
  .avatarSection{
    background-color: unset;
    padding-top: 48px;
    position: unset;
  }

  .userName {
    margin-top: 20px;
  }

  .avatarWrapper {
    margin-bottom: 0;
  }
}

@media screen and (max-width: $sm-max-width) {
  .title {
    margin-bottom: 10px;
  }

  .avatarSection {
    top: 85px;
  }
}

@media screen and (max-width: 1105px) {
  .deckListWrapper {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1106px) and (max-width: 1270px) {
  .deckWidthWrapper, .bio {
    margin: 0 auto;
    width: 855px;
  }
}
