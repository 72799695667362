@import '../../variables.module.scss';

.footer {
  min-height: 300px;
  background-color: #3884cf;
  position: relative;
  color: white;
  padding: 40px;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 0,
}

.footerInner {
  max-width: 1440px;
  display: flex;
  flex-flow: column;
  flex: 1;
  width: 100%;
}

.wave {
  background-image: url(../../images/wave.svg);
  height: 12px;
  position: absolute;
  width: 100%;
  top: -12px;
  left: 0;
  background-size: 1440px;
  background-repeat: repeat;
}

.dolphin {
  position: absolute;
  left: 12%;
  top: -28px;
  min-width: 60px;
  width: 6%;
}

.pitch {
  font-size: 24px;
  margin: 20px auto;
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  letter-spacing: 0.255em;
  text-align: center;
}

.copyright {
  font-size: 14px;
  color: #AFD6EF;
  font-weight: 500;
  letter-spacing: 0.12em;
}

.simple {
  min-height: 100px;
  background: none;
  align-items: bottom;

  .copyright {
    color: #3884CF;
  }

  .footerInner {
    justify-content: end;
  }
}

.publicHome {
  background-color: #3884CF;

  .inner {
    padding: 50px 30px 60px;
    display: flex;
    max-width: 1160px;
    margin: 0 auto;
    justify-content: space-between;
  }

  .left, .right {
    display: flex;
    flex-flow: column;
  }

  .copyright {
    font-size: 14px;
    letter-spacing: 0.12em;
    color: #0FD4CB;
    font-weight: 600;
  }

  .systemLanguage {
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-bottom: 27px;
    margin-top: 8px;
    letter-spacing: 0.1em;
  }

  .buttons {
    display: flex;
    margin-bottom: 23px;
  }

  .ig {
    margin-right: 23px;
  }

  .ig, .fb {
    &:hover {
      filter: brightness(90%);
    }
  }

  .select {
    padding-left: 0 !important;
    :global {
      option { 
        color: initial;
        font-weight: normal;
      }
    }
  }
}

@media screen and (max-width: $sm-max-width) {
  .footer {
    min-height: 200px;
    display: none;
  }

  .pitch, .copyright {
    display: none;
  }

  .simple {
    min-height: 200px;
  }

  .publicHome {
    .copyright {
      display: block;
    }
  }
}


@media screen and (max-width: 677px) {

  .publicHome {
    .inner {
      flex-flow: column-reverse;
    }

    .right {
      flex-flow: row;
      justify-content: space-between;
    }

    .left {
      align-items: flex-end;
      text-align: right;
    }
  }
}
