@import '../../variables.module.scss';

.avatarSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #2D65C2;
  padding-bottom: 100px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 46px;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.5)
}

.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;

  .left, .right {
    flex: 1;
  }

  .middle {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right {
    display: flex;
  }
}

.friendActions {
  padding-left: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  .buttons {
    display: flex;
  }
}

.friendActionsText {
  font-size: 14px;
  color: #FEDF00;
  margin-bottom: 3px;
  max-width: 90px;
}

.avatarBox {
  position: relative;
  width: 110px;
}

.avatar {
  border-color: #67D29E;
  border: 1px;
}

.imageInput {
  display: none;
}

.userName {
  font-family: $title-font-family;
  font-size: 32px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
}

.userLanguages {
  font-family: $normal-font-family;
  font-size: 16px;
  color: #0fd4cb;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-weight: 500;
  text-align: center;
}

.statisticsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 600px;
  margin-top: 50px;
  align-self: center;
  width: 100%;
}

.statisticsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 5px;
  flex: 1;
}

.statisticsNumber {
  font-family: $title-font-family;
  font-size: 40px;
  color: #67D29E;
}

.statisticsTitle {
  font-family: $normal-font-family;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.deckListWrapper {
  padding: 20px 15px;
  display: flex;
  flex-flow: column;
  max-width: 1180px;
  margin: 0 auto;
}

.accountDecksTitle {
  margin-top: 40px;
  margin-bottom: 30px;
  font-family: $title-font-family;
  font-size: 20px;
  color: #2D65C2;
  text-transform: uppercase;
}

.zh {
  .accountDecksTitle {
    font-weight: 500;
  }
}

.locked {
  font-family: $normal-font-family;
  font-size: 14px;
  margin-left: 20px;
  text-transform: none;
  font-weight: normal;
  color: #6F7575;
}


@media screen and (max-width: $sm-max-width) {
  .avatarSection {
    padding-top: 20px;
  }

  .accountDecksTitle {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1105px) {
  .deckListWrapper {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1106px) and (max-width: 1270px) {
  .deckWidthWrapper {
    margin: 0 auto;
    width: 855px;
  }
}

@media screen and (max-width: 630px) {
  .statisticsWrapper {
    width: 90%;
    min-width: 300px;
    margin-top: 20px;
  }
  .avatarSection {
    padding-bottom: 40px;
  }
}
