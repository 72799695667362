@import '../../variables.module.scss';

.outer {
  flex: 1;
  background-image: linear-gradient( rgba(127, 252, 255, .5), rgba(255, 255, 255, 1));
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 50px 20px;
  max-width: 1440px;
  margin: 0 auto;
}

.deckCardShow {
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.cardPreviewWrapper {
  padding: 0 20px;
  margin-bottom: 53px;
}

.navigator {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: flex-end;
  max-width: 360px;
  margin: 0 auto;
}

.deckName {
  font-family: $normal-font-family;
  font-weight: 700;
  font-size: 18px;
  text-decoration-line: underline;
  color: #2D65C2;
  display: block;
  text-align: left;
  margin-right: 30px;
}

.count {
  font-family: $normal-font-family;
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  color: #0FD4CB;
}

.controlButton {
  img {
    display: inline;
  }
  &:hover {
    filter: brightness(70%);
  }
}

.emptylist {
  font-size: 16px;
  color: #97ACD9;
  line-height: 30px;
  margin: 10px;
  text-align: center;
}

.noCard {
  text-align: center;
}

.slideWrapper {
  overflow: visible !important;
}

.swipeableView {
  overflow: visible !important;
}

.editOuter {
  width: 113px;
  height: 43px;
}

.separator {
  height: 1px;
  background-color: #d2d2d2;
  margin-bottom: 30px;
}

.information {
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  .title {
    color: #16B5DF;
    font-family: $normal-font-family;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .content {
    color: black;
    font-family: $normal-font-family;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
    white-space: pre-wrap;
  }
}

@media screen and (max-width: $sm-max-width) {
  .top {
    padding: 16px 20px 20px;
  }
  .information {
    padding-left: 20px;
    padding-right: 20px;
  }
}
