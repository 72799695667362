.deleteDeck {
  font-weight: bold;
  color: #2D65C2;
  margin-left: 4px;
  margin-right: 4px;
}

.moreActionButton {
  &:hover {
    filter: brightness(90%);
  }
}

.menu {
  border: 1px solid #3884CF;
  border-radius: 14px;
  padding: 10px 0 !important;
}

.menuItem {
  text-align: center;
  width: 200px;
  font-weight: 600;
  font-size: 14px;
  color: #2D65C2;
  text-transform: uppercase;
  line-height: 22px;
  &.delete {
    color: red;
    font-style: italic;
  }
}

.imageInput {
  display: none;
}

