@import '../../variables.module.scss';

.outer {
  flex: 1;
}

.cardForm {
  max-width: 1152px;
  margin: 0 auto;
  padding: 40px;
}

.inputTitle {
  font-family: $normal-font-family;
  font-weight: 600;
  font-size: 16px;
  color: #16B5DF;
  margin-bottom: 4px;
  height: 24px;
}

.inputGroup {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.inputRow {
  display: flex;
  margin-bottom: 66px;
}

.inputRowSpacer {
  flex: 0.1;
  max-width: 72px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.cardPreviewWrapper {
  margin-bottom: 53px;
}

.cardPreview {
  margin-bottom: 40px;
  position: relative;
}

.credit {
  font-family: $normal-font-family;
  font-size: 16px;
  font-weight: 400px;
  color: #C4C4C4;
  text-align: right;
  margin-top: -40px;
  margin-bottom: 40px;
}

.imageCheckbox {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 16px;
  color: #97ACD9;
  display: flex;
  align-items: center;
  span {
    margin-right: 15px;
  }
}

@media screen and (max-width: $sm-max-width) {
  .buttons {
    flex-flow: column;
    align-items: center;
  }
  .cardForm {
    max-width: 800px
  }
  .inputRow {
    flex-flow: column;
    margin-bottom: 0;
  }
  .inputGroup {
    margin-bottom: 40px;
  }
  .save {
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
  .cardPreview {
    margin-bottom: 20px;
  }
  .cardForm {
    max-width: 1152px;
    margin: 0 auto;
    padding: 40px 15px;
  }
  .credit {
    margin-top: -20px;
  }

  .imageCheckbox {
    position: relative;
    justify-content: flex-end;
    margin-top: 30px;
  }
}
