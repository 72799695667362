@import '../../variables.module.scss';

.meaningToWordMC {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.questionBox {
  max-width: 100%;
  width: 348px;
  height: 190px;
  background: #FFFFFF;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: $normal-font-family;
  font-weight: 600;
  font-size: 24px;
}

.instruction {
  margin-top: 42px;
  margin-bottom: 31px;
  font-family: $normal-font-family;
  font-size: 14px;
}

.choices {
  margin: 0 auto;
  max-width: 750px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: $sm-max-width) {
  .choices {
    max-width: 375px;
    justify-content: center;
  }
  .instruction {
    margin-top: 21px;
    margin-bottom: 21px;
  }
}
