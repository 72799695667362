@import '../../variables.module.scss';

.playResultOuter {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.playResult {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 40px;
}

.title {
  font-family: $title-font-family;
  font-size: 18px;
  text-align: center;
  color: #416B99;
  text-transform: uppercase;
  margin-bottom: 14px;
  margin-top: 20px;
}

.separator {
  width: 1px;
  height: 60px;
  background-color: #E9E9E9;
  margin: 0 53px;
}

.image {
  margin-bottom: 20px;
}

.scores {
  display: flex;
  align-items: center;
  width: 100%;
}

.right {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.left {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.scoreName {
  font-family: $normal-font-family;
  font-size: 14px;
  color: #97ACD9;
  text-align: center;
}

.challenged {
  font-family: $title-font-family;
  font-size: 64px;
  color: #2D65C2;
  text-align: center;
}

.correct {
  font-family: $title-font-family;
  font-size: 64px;
  color: #67D29E;
  text-align: center;
}

.revision {
  background: #D7EFEE;
  width: 100%;
  flex: 1;
}

.revisionInner {
  width: 100%;
  margin: 0 auto;
  padding: 37px 50px 34px;
  max-width: 1440px;
  display: flex;
  align-items: center;
}

.revisionTitle {
  text-align: center;
  font-family: $normal-font-family;
  font-size: 14px;
  color: #97ACD9;
  padding-top: 40px;
  padding-bottom: 10px;
}

@media screen and (max-width: $sm-max-width) {
  .revision {
    padding-bottom: 100px;
  }
  .revisionTitle {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}
