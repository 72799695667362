@import '../../variables.module.scss';

.revision {
  width: 100%;
}

.header {
  font-family: $normal-font-family;
  font-weight: normal;
  letter-spacing: 0.17em;
  color: #3884CF;
  text-transform: uppercase;
  margin-bottom: 30px !important;
}

.row {
  display: flex;
  margin-bottom: 47px;
}

.statusCol {
  flex-shrink: 0;
  margin-right: 65px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-self: baseline;
}

.wordCol {
  flex: 1;
  padding-left: 65px;
}

.meaningCol {
  flex: 1;
  margin-right: 76px;
}

.buttonCol {
  flex-shrink: 0;
  width: 135px;
  display: flex;
  justify-content: space-between;
}

.zh {
  .header {
    font-style: normal;
  }
}

@media screen and (max-width: 1430px) {
  .statusCol {
    margin-right: 20px;
  }

  .meaningCol {
    margin-right: 40px;
  }
}

@media screen and (max-width: $sm-max-width) {
  .header {
    display: none;
  }
  .meaningCol {
    display: none;
  }

  .wordCol {
    padding-left: 0;
  }

  .buttonCol {
    width: auto;
  }

  .row {
    margin-bottom: 32px;
  }

  .statusCol {
    width: 40px;
  }
}
