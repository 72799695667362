@import '../../variables.module.scss';

.cardPreviewOuter {
  position: relative;
  margin: 0 auto;
  width: max-content;
  max-width: calc(100vw - 40px);
}

.cardPreview {
  background-color: white;
  border: 1px solid #97ACD9;
  display: flex;
  flex-flow: column;
  border-radius: 24px;
  position: relative;
  align-items: center;
  width: 343px;
  height: 432px;
  max-width: calc(100vw - 40px);
  box-shadow: 8px 8px 5px -4px #C0C0C0;
  &.hideShadow {
    box-shadow: none;
  }
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-content: center;
  justify-content: center;
  border-radius: 24px 24px 0 0;
  height: 251px;
  max-height: 251px;
  width: 100%;
  flex: 10 1;
  position: relative;
}

.scoreWrapper {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50%;
}

.scoreIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 15px;
}

.image {
  height: 100%;
}

.video {
  object-fit: cover;
  height: 100%;
}

.flip {
  position: absolute !important;
  right: 15px;
  bottom: 20px;
}

.cardPreviewBack {
  background-color: white;
  border: 1px solid #97ACD9;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  border-radius: 24px;
  position: relative;
  align-items: center;
  width: 343px;
  height: 432px;
  padding: 30px;
  max-width: calc(100vw - 40px);
  box-shadow: 8px 8px 5px -4px #cccccc;
  overflow: hidden;
  &.hideShadow {
    box-shadow: none;
  }
}

.meaning {
  flex: 1;
  font-family: $normal-font-family;
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  color: #2D65C2;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.wordAndPronunciation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0;
  margin: 20px 15px 50px;
}

.word {
  font-size: 34px;
  font-family: $normal-font-family;
  font-weight: 600;
  line-height: 39px;
  color: #2D65C2;
  text-align: center;
  margin: 0 10px;
  word-break: break-word;
}

.pronunciation {
  font-family: Arial;
  font-size: 14px;
  color: #C1C1C1;
  text-align: center;
  word-break: break-word;
}

.nextImage {
  font-Size: 16px;
  font-family: $normal-font-family;
  font-weight: 700;
  color: #F79411;
  max-width: 344px;
  align-self: flex-start;
  margin-left: 20px;
  text-decoration: underline;
}

.nextImage:hover{
  cursor: pointer;
}

.downloadOuter {
  position: absolute;
  width: 279px;
  height: 51px;
  bottom: -15px;
  left: -15px;
  max-width: 279px;
}

.audioButton {
  height: 55px;
}

@media screen and (max-width: $sm-max-width) {
  .downloadOuter {
    width: 70%;
    min-width: 224px;
  }
}
