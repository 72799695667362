.deckEdit {
  flex: 1;
  margin-bottom: 150px;
}

.inner {
  padding: 43px 16px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: #16B5DF;
  text-align: center;
}

.desc {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #AEADAD;
  margin-top: 21px;
  max-width: 400px;
  margin-bottom: 54px;
  margin-left: auto;
  margin-right: auto;
}

.addWrapper {
  width: 543px;
  max-width: calc(100% - 32px);
}

.gameModeWrapper, .categoryWrapper {
  width: 543px;
  max-width: calc(100% - 32px);
  margin-top: 50px;
}

.categoryWrapper {
  .title {
    margin-bottom: 54px;
  }
}

.gameModeRow {
  font-size: 16px;
  color: #173C83;
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}

.gameModeName {
  display: flex;
}

.index {
  width: 16px;
}

.privacyWrapper {
  width: 500px;
  margin-top: 50px;
  max-width: calc(100% - 32px);
  margin-bottom: 30px;
}

.switchWrapper {
  display: flex;
  margin-top: 40px;
  color: #C4C4C4;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  .text {
    flex: 1;
    margin: 0 20px;
    h4 {
      font-weight: 500;
    }
    h3 {
      font-weight: 600;
    }
  }
  .active {
    color: #173C83;
  }
  h4 {
    font-weight: 400;
    font-size: 14px;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
  }
}
