@import '../../variables.module.scss';

.playOuter {
  flex: 1;
  background: #D7EFEE;
}

.play {
  flex: 1;
  padding: 37px 50px 34px;
  max-width: 1440px;
  margin: 0 auto;
}

.progressSection {
  margin-bottom: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progressText {
  font-family: $normal-font-family;
  font-size: 14px;
  margin-left: 50px;
}

.correctCount {
  display: flex;
  font-family: $normal-font-family;
  font-size: 14px;
  color: #3B966A;
  margin-bottom: 60px;

  img {
    margin-right: 12px;
  }
}

@media screen and (max-width: $sm-max-width) {
  .play {
    padding: 16px 12px;
  }
  .correctCount {
    margin-bottom: 15px;
  }
}
