@import './variables.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@400;600&family=Quicksand:wght@300;400;500;600;700&family=Righteous&family=Roboto:wght@500&display=swap');

html {
  font-family: $normal-font-family;
  font-size: 14px;
  font-weight: 400;
}

a {
  text-decoration: none;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
