@import '../../variables.module.scss';

.outer {
  flex: 1;
  background-image: linear-gradient(170deg, #7ff5ff, white, white);
  margin-bottom: 60px;
}

.inner {
  margin: 0 auto;
  padding: 45px 50px;
  max-width: 1248px;
  margin-bottom: 100px;
}

.title {
  position: relative;
  margin-bottom: 30px;
  font-family: $title-font-family;
  font-size: 20px;
  color: #2D65C2;
  text-transform: uppercase;
}

.more {
  position: absolute;
  right: 0;
  top: 0;
  color: #F79411;
  font-family: $normal-font-family;
  font-weight: 600;
  font-style: italic;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
}

.progressCounts {
  display: flex;
  justify-content: center;
}

.progressCountTitle {
  font-family: $normal-font-family;
  text-align: center;
  color: #97ACD9;
  text-transform: uppercase;
}

.progressCount {
  margin: 0px 10px;
  max-width: 33%;
}

.progressCountNewWord, .progressCountStudying, .progressCountAcquired {
  height: 160px;
  min-width: 160px;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 14px;
  background: #FFFFFF;
  border: 1px solid #67D29E;
  font-family: $title-font-family;
  font-size: 48px;
  line-height: 160px;
  box-shadow: 3px 3px 3px 0px #d3d3d3;

  &:hover {
    filter: brightness(90%);
  }

  @media screen and (max-width: $sm-max-width) {
    font-size: 42px;
  }
}

.progressLinkDisabled {
  pointer-events: none ;
}

.progressCountNewWord {
  color: #97ACD9;
}

.progressCountStudying {
  color: #0FD4CB;
}

.progressCountAcquired {
  color: #2D65C2;
}

.seperator {
  height: 1px;
  background-color: #d2d2d2;
  margin-top: 70px;
  margin-bottom: 50px;
}

.zh {
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .progressCountTitle {
    font-size: 16px;
  }
}

.subMenuWrapper {
  width: 744px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.flashcardImage {
  height: 24px;
}

.createFlashcardButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 46px;
}

.teacherDecksTitle {
  font-weight: 700;
  font-size: 18px;
  color: #16B5DF;
  margin-bottom: 11px;
  display: flex;
  img {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1264px) {
  .widthWrapper {
    max-width: 861px;
    margin: 0 auto;
  }
}

@media screen and (max-width: $sm-max-width) {
  .inner {
    padding: 20px 16px 10px;
  }

  .progressCount {
    margin: 0px 4px;
  }

  .seperator {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .subMenuWrapper {
    margin-bottom: 20px;
  }

  .titleRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .progressCountNewWord, .progressCountStudying, .progressCountAcquired {
    height: 110px;
    min-width: 110px;
    line-height: 110px;
  }
  .createFlashcardButtonWrapper {
    justify-content: center;
    margin-top: 20px;
  }

}

@media screen and (max-width: 797px) {
  .subMenuWrapper {
    width: 372px;
  }
}
