@import '../../variables.module.scss';

$outer-padding-1: 16px;
$outer-padding-5: 25px;
$width-5: 960px;

.topBarOuter {
  background: #F3F4F5;
  margin-bottom: 30px;
}

.topBar {
  max-width: 1206px;
  margin: 0 auto;
}


.topBar {
  padding: 12px $outer-padding-1;
  margin: 0 auto;
  @media screen and (min-width: $width-5) {
    padding: 28px $outer-padding-5;
  }
}

.row1, .row2, .row3, .row4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row1 {
  margin-bottom: 8px;
}

.row3 {
  margin-bottom: 16px;
  margin-top: -10px;
  font-weight: 500;
}

.progress {
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
}

.progressValue {
  font-weight: 700;
  font-size: 24px;
  color: #2D65C2;
}

.deckName {
  font-family: $normal-font-family;
  font-size: 16px;
  a {
    color: #2D65C2;
    text-decoration-line: underline;
    font-weight: 600;
  }
  margin-right: 30px;
} 

.deckCount {
  font-family: $normal-font-family;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
}

.deckNameBold {
  font-weight: bold;
  font-size: 18px;
}

@media screen and (max-width: 749px) {
  .row2 {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .deckCount {
    margin-bottom: 4px;
  }
}
