.content {
  padding: 30px 20px 50px;
}

.title {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #3884CF;
  margin-bottom: 80px;
  margin-top: 10px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 142px;
}
