@import '../../variables.module.scss';

$max-width: 1160px;

.section1 {
  background: #E1FDFF;

  .logo {
    margin-left: 10px;
  }

  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    text-align: right;
    color: #264890;
    margin-top: 200px;
    margin-bottom: 5px;
  }

  .subTitle {
    font-weight: 600;
    font-size: 28px;
    text-align: right;
    color: #959595;
    margin-bottom: 150px;
  }
}

.section1, .section2 {
  padding: 44px 30px;
}

.section1Inner, .section2Inner, .section3Inner {
  max-width: $max-width;
  margin: 0 auto;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginButton, .blogButton {
  border-radius: 31px;
  width: 149px;
  height: 47px;
  font-size: 18px;
  line-height: 47px;
  font-family: $title-font-family;
  text-transform: uppercase;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
  display: inline-block;
  text-align: center;
}

.blogButton {
  background: #F4A900;
  color: white;
}

.loginButton {
  background: white;
  border: 1px solid #2D65C2;
  color: #2D65C2;
  margin-left: 11px;
}

.section2 {
  background: #16B5DF;
  position: relative;
}

.section2Inner {
  position: relative;
  min-height: 260px;
}

.wave {
  background-image: url(../../images/home-public/wave.svg);
  height: 12px;
  position: absolute;
  width: 100%;
  top: -12px;
  left: 0;
  background-size: 1440px;
  background-repeat: repeat;
}

.phone {
  position: absolute;
  bottom: 0;
  left: 0;
}

.section2Right {
  margin-left: 450px;

  @media screen and (max-width: 1117px) {
    margin-left: 400px;
  }
}

.audioButton, .loginButton, .blogButton, .signupButton {
  &:hover {
    filter: brightness(90%);
  }
}

.chooseLangTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #30FDF4;
  margin-bottom: 32px;
}

.audioRow {
  display: flex;
  margin-left: 100px;
  justify-content: space-between;
  margin-bottom: 12px;

  @media screen and (max-width: 1176px) {
    margin-left: 30px;
  }
}

.audioLang {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 7px;
  color: #FFFFFF;
}

.section2InnerMobile {
  display: none;
}

.section3 {
  padding: 127px 30px;

  .section3Inner {
    display: flex;
  }

  .section3Right {
    margin-left: 100px;
  }

  .section3Left {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #264890;
    margin-bottom: 20px;
  }

  .subTitle {
    font-size: 20px;
    line-height: 155%;
    color: #264890;
    margin-bottom: 63px;
  }

  .titleMobile {
    display: none;
  }

  br {
    display: none;
  }
}

.signupButton {
  height: 70px;
  background: #2D65C2;
  border: 3px solid #FFFFFF;
  border-radius: 44px;
  width: 289px;
  display: flex;
  color: white;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  .free {
    font-weight: bold;
    font-size: 32px;
    margin-left: 7px;
  }
}


@media screen and (max-width: 1030px) {
  .topBarButtons, .section2Inner {
    display: none;
  }

  .section1 {
    .logo {
      margin-left: 0;
    }

    .title {
      margin-top: 80px;
      font-size: 30px;
      line-height: 37px;
    }

    .subTitle {
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .section2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section2InnerMobile {
    display: flex;
    justify-content: center;
    align-items: center;

    .loginButton {
      margin-left: 30px;
    }
  }

  .section3Inner {
    flex-flow: column-reverse;
    text-align: center;

    .section3Right {
      margin-left: 0;
      margin-bottom: 36px
    }

    .section3Left {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .title {
      display: none;
    }

    .titleMobile {
      display: block;
      font-weight: bold;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      color: #264890;
      margin-bottom: 43px;
    }

    .subTitle {
      font-size: 20px;
    }

    br {
      display: inline;
    }

    .cardPreview {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
}

.section4 {
  display: flex;
  flex-flow: column;
  background: #2D65C2;
  align-items: center;
  padding: 100px 30px;
  text-align: center;

  .section4Title {
    font-size: 24px;
    color: #FFFFFF;
    margin-top: 28px;
    margin-bottom: 60px;

    .bold {
      font-size: 36px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .cardSwiper {
    width: 100%;
    overflow: visible;
    margin-bottom: 90px;
  }

  .card {
    width: 522px;
    height: 599px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 70px;
    padding-bottom: 30px;
    max-width: 100%;
    background: #FFFFFF;
    border: 1px solid #97ACD9;
    box-sizing: border-box;
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.25);
    border-radius: 27px;
    user-select: none;
  }

  .cardTitle, .freeCardTitle {
    font-weight: 600;
    font-size: 28px;
    color: #2D65C2;
    margin-bottom: 24px;
    margin-top: 6px;
    min-height: 50px;
  }

  .freeCardTitle {
    margin-top: 60px;
    font-weight: bold;
    font-size: 48px;
  }

  .cardDesc {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .cardImg {
    margin-top: 40px;
  }

  :global {
    .swiper-pagination {
      bottom: 30px;
    }
  }

  .device {
    font-weight: bold;
    font-size: 36px;
    color: #FFFFFF;
    margin-bottom: 24px;
  }

  .availableOn {
    font-size: 20px;
    line-height: 34px;
    color: #FFFFFF;
    .bold {
      font-size: 28px;
      font-weight: 700;
      color: #0FD4CB;
    }
  }

  .devices {
    display: flex;
    width: 200px;
    justify-content: space-between;
    margin-top: 40px;
  }
}

.section5 {
  padding-top: 190px;
  padding-bottom: 220px;
  position: relative;

  .slide {
    width: 40vw;
    min-width: 700px;
  }

  .review {
    width: 530px;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 30px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    h4 {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 21px;
      margin-top: 25px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #A9A9A9;
      align-self: flex-end;
    }
  }
    
  .signupButton {
    background: #16B5DF;
    position: absolute;
    top: -35px;
    left: calc(50% - 145px);
  }
}

@media screen and (max-width: 600px) {
  .section2InnerMobile {
    flex-flow: column;

    .loginButton {
      margin-left: 0;
      margin-top: 19px;
    }
  }

  .section4 {
    .section4Title {
      margin-bottom: 50px;
    }

    .card {
      height: 482px;
      width: 370px;
      padding: 0 30px;
    }

    .cardTitle {
      font-size: 24px;
      min-height: 40px;
    }

    .cardDesc {
      font-size: 18px;
    }

    .cardImg {
      margin-top: 20px;
    }

    .freeCardTitle {
      font-size: 36px;
      margin-top: 40px;
    }

    .cardImg {
      width: 240px;
    }
  }

  .section5 {
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: right;
    .slide {
      width: 100%;
      min-width: initial;
    }

    .review {
      align-items: flex-end;
      h4 {
        font-weight: 400;
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .section4 {
    .card {
      height: 482px;
      padding: 0 30px;
    }
  }
}


.zh {
  .section1 {
    .title {
      letter-spacing: 0.37em;
    }

    .subTitle {
      letter-spacing: 0.40em;
      margin-right: 5px;
    }

    @media screen and (max-width: 1030px) {
      .title {
        letter-spacing: 0;
        font-size: 28px;
      }

      .subTitle {
        letter-spacing: 0;
      }
    }
  }

  .signupButton {
    letter-spacing: 0.245em;
    font-weight: 700;
  }

  .section4 {
    .section4Title {
      letter-spacing: 0.50em;
    }
    .availableOn {
      letter-spacing: 0.30em;
      @media screen and (max-width: 1030px) {
        letter-spacing: 0.20em;
      }
    }

  }
}
