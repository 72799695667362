@import '../../variables.module.scss';

.outer {
  flex: 1;
}

.ankiConfig {
  padding: 30px 20px 50px;
  max-width: 1152px;
  margin: 0 auto;
  padding: 37px 50px 34px;
}

.progress {
  margin-top: 60px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 20px;
    color: #2D65C2;
    margin-top: 20px;
    font-family: $title-font-family;
  }
}

.label {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #16B5DF;
  margin-bottom: 4px !important;
  height: 24px;
  font-family: $normal-font-family;
}

.formControl {
  margin-bottom: 20px;
}

.content {
  font-size: 16px;
  font-weight: 600;
}

.card {
  margin-top: 40px;
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.errorMsg {
  font-family: $normal-font-family;
  color: red;
  font-size: 14px;
  text-align: right;
}

@media screen and (max-width: $sm-max-width) {
  .ankiConfig {
    padding: 35px 16px 10px;
    padding-bottom: 150px;
  }
}
