@import '../../variables.module.scss';

.image {
  width: 146px;
  border-radius: 10px;
  background-size: cover;
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

.placeholderImage {
  width: 62px;
}

.deckName {
  font-family: $normal-font-family;
  margin-top: 6px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
  padding-right: 50px;
}

.linkWrapper {
  border-radius: 15px;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
  padding: 9px 11px;
  background: white;
  width: 348px;
  max-width: calc(100vw - 40px);
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
  position: relative;
  height: 200px;
  display: flex;
  flex-flow: column;
}

.follower {
  display: flex;
  margin-top: 5px;
  margin-right: 10px;
}

.followerNumber {
  font-family: $normal-font-family;
  font-size: 18px;
  color: #f94141;
  margin-left: 9px;
  margin-top: 4px;
}

.topRight {
  position: absolute;
  top: -17px;
  right: 0;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  flex: 1;
}

.right {
  text-align: right;
  color: #2D65C2;
  margin-bottom: 6px;
}

.progress {
  font-size: 48px;
  font-family: $title-font-family;
  font-weight: 400;
  line-height: 58px;
}

.newDeck {
  margin-bottom: 25px;
}
