@import '../../variables.module.scss';

.teachersDecks {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fit, 263px);
  grid-gap: 10px 32px;
  margin: 0 auto;
  margin-bottom: 85px;
}

.teachersDecksMobile {
  display: none;
  margin-bottom: 40px;
}

.slide {
  width: 263px;
}

@media screen and (max-width: 1105px) {
  .teachersDecksMobile {
    display: block;
  }
  .teachersDecks {
    display: none;
  }
}

@media screen and (max-width: $sm-max-width) {
  .teachersDecksMobile {
    overflow: visible;
  }
}
