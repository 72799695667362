@import '../../variables.module.scss';

.slide {
  width: 110px;
}

.card {
  border-radius: 10px;
  box-Shadow: 3px 3px 1px 0px rgba(0,0,0,.25);
  margin-bottom: 5px;
}

.placeholderImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 10px;
  background-color: white;
}

.placeholderImage {
  width: 50%;
}

.spinnerWrapper {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $sm-max-width) {
  .swiper {
    overflow: visible;
  }
}
