.fullName {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

.row {
  border-bottom: 0.5px solid #D2D2D2;
  display: flex;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 12px;
  align-items: center;

  .left {
    display: flex;
    flex: 1;
    margin-right: 12px;
    align-items: center;
  }
}
