@import '../../variables.module.scss';

.outer {
  flex: 1;
  margin-bottom: 150px;
}

.imageInput {
  display: none;
}  

.createCard {
  width: 600px;
  background: #FFFFFF;
  border: 2px solid #97ACD9;
  margin: 36px auto;
  padding: 28px 38px;
  border-radius: 15px;
}

.title {
  font-family: $normal-font-family;
  font-size: 16px;
  color: #3884CF;
  margin-bottom: 43px;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  align-self: normal;
}

.button {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.createCardInner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 435px);
  flex-flow: column;
  min-height: 480px;
}

.spacer {
  flex: 1;
}

.instruction {
  font-size: 16px;
  text-align: center;
  color: #3884CF;
  margin-bottom: 40px;
}

.meaning {
  font-size: 16px;
  color: #AEADAD;
  margin-top: 16px;
  margin-bottom: 30px;
}

@media screen and (max-width: $sm-max-width) {
  .createCard {
    width: auto;
    border: none;
    padding: 0 16px;
    max-width: 500px;
    margin: 10px auto;
  }

  .createCardInner {
    height: calc(100vh - 265px);
  }

  .next {
    max-width: calc(100vw - 32px) !important;
  }

}
