@import '../../variables.module.scss';

.wordToImageMC {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.questionBox {
  max-width: 100%;
  width: 348px;
  height: 190px;
  background: #FFFFFF;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: $normal-font-family;
  font-weight: 600;
  font-size: 24px;
}

.instruction {
  margin-top: 42px;
  margin-bottom: 31px;
  font-family: $normal-font-family;
  font-size: 14px;
}

.audio {
  position: absolute !important;
  bottom: 12px;
  right: 14px;
}

.choices {
  display: flex;
  align-content: center;
  justify-content: center;
}

.spinner {
  width:200px;
  position: absolute;
  align-self: center;
  padding: 0px;
  margin: 0px;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-style: solid;
  border-color: #000000;
  border-width: 1px;
  margin: 0px 12px;
  overflow: hidden;

  &.selected {
    border-color: #2D65C2;
    border-width: 3px;
  }

  &.wrong {
    border-color: #FF6A91;
    border-width: 5px;
  }

  &.correct {
    border-color: #67D29E;
    border-width: 5px;
  }

  &.isShowingAnswer {
    pointer-events: none;
  }
}

.image, .imageWrapper {
  width: 204px;
  height: 152px;
}

.iconStyle {
  position: absolute;
  width: 40px;
  background-color:white;
  border-radius: 50%;
}

@media screen and (max-width: $sm-max-width) {
  .instruction {
    margin-top: 21px;
    margin-bottom: 21px;
  }

  .choices {
    flex-flow: wrap;
    width: 352px;
  }

  .image, .imageWrapper {
    width: 170px;
    height: 126px;
  }

  .imageWrapper {
    margin: 4px 3px;
  }
}
