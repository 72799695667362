@import '../../variables.module.scss';

.playIndexOuter {
  flex: 1;
  padding-bottom: 100px;
}

.playIndex {
  flex: 1;
  padding: 50px 50px 34px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whichDeck {
  width: 700px;
  font-family: $normal-font-family;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 30px;
  max-width: 100%;
  text-align: center;
  color: #2D65C2;
}

.autoModeWrapper {
  max-width: 100%;
}

@media screen and (max-width: $sm-max-width) {
  .playIndex {
    padding: 37px 12px;
  }

}
