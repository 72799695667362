@import '../../variables.module.scss';

.shuffledLetter {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.questionBox {
  max-width: 100%;
  width: 348px;
  height: 190px;
  background: #FFFFFF;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: $normal-font-family;
  font-weight: 600;
  font-size: 24px;
}

.instruction {
  margin-top: 42px;
  margin-bottom: 31px;
  font-family: $normal-font-family;
  font-size: 14px;
}

.audio {
  position: absolute !important;
  bottom: 12px;
  right: 14px;
}

.tickIconStyle {
  position:absolute;
  margin-left:280px;
  width:26px;
  border-radius: 50%;
  background: white;
}

.crossIconStyle {
  position:absolute;
  margin-left:280px;
  width: 26px;
  border-radius: 50%;
  background: white;
}

.correctAnsWrapper{
  font-family: $normal-font-family;
  font-style: normal;
  display:flex;
  flex-flow: row;
  text-align: center;
  font-size: 18px !important;
  margin-bottom: 10px;
  color: #389F6D;
}

.correctAnsTitle{
  font-weight: 300;
}

.correctAns{
  font-weight: 600;
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: $sm-max-width) {
  .choices {
    max-width: 375px;
    justify-content: center;
  }
  .instruction {
    margin-top: 21px;
    margin-bottom: 21px;
  }
}
