@import '../../variables.module.scss';

.headerBlock {
  display:flex;
  flex-flow:column;
}

.backRegister {
  padding-top: 10px;
}

.optionBox {
  align-self: flex-end;
  padding-top: 20px;
}

.title {
  font-family: $normal-font-family;
  font-size: 14px;
  color: black;
  padding-top: 30px;
  font-weight: 600;
}

.paragraph {
  font-family: $normal-font-family;
  font-size: 14px;
  color: #949191;
  padding-top: 10px;
}
