@import '../../variables.module.scss';

.outer {
  flex: 1;
  margin-bottom: 60px;
}

.inner {
  margin: 0 auto;
  padding: 70px 50px 45px 50px;
  max-width: 1248px;
  margin-bottom: 100px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 30px;
  font-family: $title-font-family;
  font-size: 20px;
  color: #2D65C2;
  text-transform: uppercase;
}

.separator {
  margin-bottom: 120px;
}

.link {
  color: #F79411;
  text-Decoration: underline;
}

.slash {
  color: #6DD8E0;
}

.spinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notice {
  font-family: $normal-font-family;
  font-size: 16px;
  color: #97ACD9;
  line-height: 30px;
  margin: 10px;
  text-align: center;
}

@media screen and (max-width: $sm-max-width) {
  .inner {
    padding: 20px 16px 10px;
  }

  .link {
    display: none;
  }

  .slash {
    display: none;
  }

  .title {
    justify-content: flex-end;
  }

  .separator {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1264px) {
  .widthWrapper {
    max-width: 1248px;
    margin: 0 auto;
  }
}
