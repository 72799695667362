.subMenu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  flex: 1;
}

.buttonWrapper:not(:first-child) {
  margin-left: -30px;
}

.arrow {
  margin-top: 5px;
}
