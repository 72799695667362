@import '../../variables.module.scss';

.blogPosts {
  display: grid;
  grid-template-columns: repeat(3, minmax(270px, 342px));
  grid-gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: $sm-max-width) {
  .blogPosts {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    justify-items: center;
  }
}
