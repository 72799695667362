.tab {
  color: #B1DAD8;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:hover {
    filter: brightness(97%);
  }
}

.play {
  background-position: center;
  background-size: contain;
  height: 95px;
  padding: 0 10px;
  transform: translateY(-45px);
  justify-content: flex-end;
}
