@import '../../variables.module.scss';

.dot {
  color: white;
  line-height: 20px;
  border-radius: 10px;
  min-width: 20px;
  text-align: center;
  font-size: 12px;
  background-color: #FFCA10;
  padding: 0 4px;
  font-family: $normal-font-family;
}
