@import '../../variables.module.scss';

.errorMsg {
  font-family: $normal-font-family;
  color: red;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.headerBlock {
  display:flex;
  flex-flow:column;
}

.backLogin {
  margin-left: -15px;
}

.messageStyle {
  font-family: $normal-font-family;
  text-align: center;
  color: #6F7575;
  font-size: 14px;
  font-weight: 400;
};
