@import '../../variables.module.scss';

.outer {
  display: flex;
  flex-flow: column;
  flex: 1;
  padding-bottom: 100px;
  background-image: linear-gradient(180deg,#7ff5ff 1%,#fff 70%);
}

.friends {
  flex: 1;
}

.subMenu {
  width: 392px;
  margin: 0 auto;
  margin-top: 46px;
  margin-bottom: 28px;
  max-width: calc(100% - 32px);
}

.notificationCount {
  position: absolute;
  top: -10px;
  right: 20px;
}

@media screen and (max-width: $sm-max-width) {
  .subMenu {
    margin-top: 20px;
  }
}
