@import '../../variables.module.scss';

.deckBox {
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.image, .placeholderImageWrapper {
  width: 115px;
  height: 115px;
  border-radius: 15px;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
  margin-right: 20px;
}

.placeholderImageWrapper {
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
}

.placeholderImage {
  width: 62px;
}

.deckName {
  font-weight: bold;
  font-size: 16px;
  font-family: $normal-font-family;
  margin-top: 6px;
}

.linkWrapper {
  display: flex;
  align-items: center;
}


@media screen and (max-width: $sm-max-width) {
  .image, .placeholderImageWrapper {
    width: 90px;
    height: 90px;
    margin-right: 15px;
  }
  .deckBox {
    height: 90px;
  }
}
