@import '../../variables.module.scss';

.playModal {
  position: fixed;
  bottom: 100px;
  top: 87px;
  left: 0;
  right: 0;
  z-index: 6;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  text-align: center;
  flex-flow: column;
  justify-content: flex-end;

  .inner {
    background: rgba(56, 103, 153, 0.95);
    padding: 32px 44px;
    .title {
      color: #3CF3EB;
      font-family: $title-font-family;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }

    .text {
      font-size: 14px;
      color: white;
      margin-bottom: 34px;

      .number {
        font-size: 18px;
        font-weight: 700;
      }
    }

  }

  &:not(.closing) {
    animation: fade-in 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    .inner {
      animation: slide-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  &.closing {
    animation: fade-out 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    .inner {
      animation: slide-down 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  @media screen and (min-width: $sm-min-width) {
    display: none;
  }
}

@keyframes slide-top {
  0% {
    transform: translatey(400px);
  }
  100% {
    transform: translatey(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translatey(0);
  }
  100% {
    transform: translatey(400px);
  }
}

@keyframes fade-in {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  100% {
    background: rgba(255, 255, 255, 0.7);
  }
}

@keyframes fade-out {
  0% {
    background: rgba(255, 255, 255, 0.7);
  }
  100% {
    background: rgba(255, 255, 255, 0);
  }
}
