@import '../../variables.module.scss';

.tabBar {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #ccc;
  font-family: $title-font-family;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  padding: 20px 10px;
  z-index: 7;
  height: 100px;
  animation: slide-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.tabBarInner {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 700px;
}

.tab {
  color: #B1DAD8;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  flex: 1;
  height: 58px;

  .imgWrapper {
    height: 30px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    position: relative;
  }
  &:hover {
    filter: brightness(97%);
  }
  &.disabled {
    filter: brightness(50%);
    opacity: 0.3;
  }
}

.play {
  background-position: center;
  background-size: contain;
  height: 95px;
  padding: 0 10px;
  transform: translateY(-45px);
  justify-content: flex-end;
  
}

.active {
  color: #2D65C2;
  -webkit-text-stroke: 0;
  font-size: 16px;
  font-weight: 600;
}

.image {
  width: 20px;
  height: 20px;
}

.notificationCount {
  position: absolute;
  top: -10px;
  right: -10px;
}

@media screen and (min-width: $sm-min-width) {
  .tabBar {
    display: none !important;
  }
}

@keyframes slide-top {
  0% {
    transform: translatey(400px);
  }
  100% {
    transform: translatey(0);
  }
}
