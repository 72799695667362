.deckForm {
  padding: 30px 20px 50px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: #16B5DF;
  margin-bottom: 10px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 142px;
  flex-flow: column;
  align-items: center;
}

.errorMsg {
  color: red;
  margin-top: 4px;
  display: block;
}
