@import '../../variables.module.scss';


$inner-spacing-1: 10px;
$inner-spacing-5: 25px;
$outer-padding-1: 16px;
$outer-padding-5: 25px;
$width-1: 263px;
$width-2: 375px;
$width-3: 553px;
$width-4: 727px;
$width-5: 960px;

.outer {
  flex: 1;
}

.deckCardEdit {
  margin-bottom: 60px;
}

.inner {
  max-width: 1206px;
  margin: 0 auto;
}

.box {
  position: relative;
  overflow: hidden;
  width: calc((100% - #{$inner-spacing-1} * 1) / 1);
  margin-right: $inner-spacing-1;
  margin-bottom: 30px;

  @media screen and (min-width: $width-1) {
    width: calc((100% - #{$inner-spacing-1} * 2) / 2);
  }

  @media screen and (min-width: $width-2) {
    width: calc((100% - #{$inner-spacing-1} * 3) / 3);
  }

  @media screen and (min-width: $width-3) {
    width: calc((100% - #{$inner-spacing-1} * 4) / 4);
  }

  @media screen and (min-width: $width-4) {
    width: calc((100% - #{$inner-spacing-1} * 5) / 5);
  }

  @media screen and (min-width: $width-5) {
    margin-right: $inner-spacing-5;
    width: calc((100% - #{$inner-spacing-5} * 6) / 6);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 $outer-padding-1;
  padding-right: calc(#{$outer-padding-1} - #{$inner-spacing-1});
  margin: 0 auto;

  @media screen and (min-width: $width-5) {
    padding: 0 $outer-padding-5;
    padding-right: calc(#{$outer-padding-5} - #{$inner-spacing-5});
  }
}

.titleText {
  font-family: $normal-font-family;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 10px;
}

.emptylist {
  font-size: 16px;
  color: #97ACD9;
  line-height: 30px;
  margin: 10px;
  text-align: center;
}

.noCard {
  text-align: center;
}

.imageWrapper {
  position: relative;
  overflow: hidden;
}

.img, .placeholder {
  border-radius: 10px;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
  position: absolute;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 5px);
  width: calc(100% - 5px);
}

.placeholder {
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  word-break: break-word;
  padding: 10px;
}

.imageWrapper:before {
  content: "";
  display: block;
  padding-top: 100%;
}

@media screen and (max-width: $sm-max-width) {
  .placeholder {
    font-size: 16px;
  }
}
