@import '../../variables.module.scss';

.imageToWordMC {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.choices {
  margin: 0 auto;
  max-width: 750px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.instruction {
  margin-top: 42px;
  margin-bottom: 31px;
  font-family: $normal-font-family;
  font-size: 14px;
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  width: 348px;
  border: 1px solid black;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-size: cover;
}

.spinner {
  align-self: center;
  position: absolute;
}

.audio {
  position: absolute !important;
  bottom: 12px;
  right: 14px;
}

.largeAudio {
  width: 60px;
  height: 60px;
}


@media screen and (max-width: $sm-max-width) {
  .choices {
    max-width: 375px;
    justify-content: center;
  }
  .instruction {
    margin-top: 21px;
    margin-bottom: 21px;
  }
}
