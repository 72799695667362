@import '../../variables.module.scss';

.discover {
  position: relative;
}

.card {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
}

.control {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000050;
  display: flex;
  padding: 6px 16px;
  justify-content: space-between;
  align-items: center;
  line-height: 28px;
}

.title {
  color: white;
  font-size: 24px;
  font-family: $normal-font-family;
  font-weight: 500;
}

.meaning {
  color: white;
  font-size: 16px;
  font-family: $normal-font-family;
  font-weight: 500;
}

.slide {
  width: 200px;
}

.currentCardWrapper {
  display: flex;
  justify-content: center;
}

.scoreIcon {
  position: absolute;
}

.video {
  object-fit: cover;
  height: 200px;
  width: 200px;
}

@media screen and (max-width: 1480px) {
  .next, .prev {
    display: none !important;
  }
}

@media screen and (max-width: $sm-max-width) {
  .swiper {
    overflow: visible;
  }
}
