.outer {
  flex: 1;
  margin-bottom: 60px;
}

.subMenuWrapper {
  width: 372px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
}

@media screen and (max-width: 630px) {
  .outer {
    margin-bottom: 120px;
  }
  .subMenuWrapper {
    margin-bottom: 20px;
  }
}
