@import '../../variables.module.scss';

.postWrapper {
  position: relative;
  max-width: 342px;
  max-height: 185px;
  min-width: 270px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: $normal-font-family;
  position: absolute;
  bottom: 15px;
  background: #00000050;
  padding: 12px 14px;
  align-items: flex-end;
  color: white;
}

.date {
  font-size: 14px;
  font-weight: 400;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.image {
  border-radius: 15px;
  width: 342px;
  height: 185px;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
}

.placeholderImageWrapper {
  display: flex;
  justify-content: center;
  height: 185px;
  min-width: 270px;
  border: 1px solid black;
  border-radius: 15px;
  background-color: white;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
}

.placeholderImage {
  width: 62px;
}