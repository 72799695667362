@import '../../variables.module.scss';

.inputTitle {
  font-family: $normal-font-family;
  font-weight: 600;
  font-size: 16px;
  color: #16B5DF;
  margin-bottom: 4px;
  height: 24px;
}

.inputGroup {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.audioControl {
  display: flex;
  align-items: center;
}

.progress {
  margin-left: 12px;
  flex: 1;
}

.audioControlInner {
  border: 1px solid #97ACD9;
  border-radius: 4px;
  flex: 1;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  margin-left: 12px;
}

@media screen and (max-width: $sm-max-width) {
  .inputGroup {
    margin-bottom: 40px;
  }
}
