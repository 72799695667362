@import '../../variables.module.scss';

.header {
  font-family: $title-font-family;
  font-size: 20px;
  color: #2D65C2;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.account {
  font-weight: 300;
  font-size: 14px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 60px;
  padding-top: 50px;
  padding-bottom: 0;
  background-color: white;
  margin-bottom: 60px;
}

.column {
  flex: 1;
}

.errorMsg {
  color: red;
  margin-top: 4px;
  display: block;
}

.subHeader {
  font-weight: 600;
  font-size: 16px;
  color: #16B5DF;
  margin-bottom: 30px;
}

.section {
  margin-bottom: 100px;
  border-top: 1px dashed #68676750;
}

.sectionInner {
  display: flex;
  flex-wrap: wrap;
}

.item {
  padding-top: 35px;
  padding-bottom: 35px;
  height: 175px;
}

.columnSpacer {
  width: 100px;
}

.dailyGoal {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.value {
  line-height: 50px;
}

.buttons {
  display: flex;
  flex-flow: column;
  align-items: center;
  * {
    text-transform: uppercase;
  }
}


.zh {
  .header {
    font-weight: 600;
  }
  .subHeader {
    font-weight: 500;
  }
}

.languagesWrapper {
  display: flex;
  margin-bottom: 14px;
  button:not(:first-child) {
    margin-left: 10px;
  }
}

@media screen and (max-width: $sm-max-width) {
  .account {
    padding: 20px 13px;
  }

  .header {
    margin-bottom: 27px;
  }

  .section {
    margin-bottom: 50px;
  }

  .sectionInner {
    flex-flow: column;
  }

  .subHeader {
    margin-bottom: 20px;
  }

  .item {
    padding-top: 30px;
    padding-bottom: 25px;
    height: auto;
  }

  .value {
    line-height: unset;
  }

  .dailyGoal {
    height: unset;
  }
}
