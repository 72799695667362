@import '../../variables.module.scss';

.friendsTab {
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1440px;
}

.title {
  font-size: 20px;
  color: #16B5DF;
  font-weight: bold;
  margin-bottom: 18px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message {
  font-size: 14px;
  color: #F79411;
}

.searchWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 49px;
}

.empty {
  font-size: 16px;
  color: #97ACD9;
  line-height: 30px;
  margin: 10px;
  margin-top: 30px;
  text-align: center;
}

.showSearchButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #F79411;
  margin-left: 11px;
}

.slide {
  width: 263px;
}

@media screen and (max-width: $sm-max-width) {
  .friendsTab {
    padding: 0 16px;
  }

  .decks {
    overflow: visible;
  }
}
