@import '../../variables.module.scss';

.welcomeToCardoze {
  font-family: $normal-font-family;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #3884CF;
  letter-spacing: 0.24em;
  text-transform: uppercase;
}

.threeStepsToSuccess {
  font-family: $title-font-family;
  font-size: 24px;
  letter-spacing: 0.12em;
  color: #2D65C2;
  text-transform: uppercase;
  text-align: center;
  margin-top: 24px;
}

.createFlashcardIcon, .reviewCardIcon ,.enhanceMemoryIcon {
  height: 136px;
  width: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2D65C2;
  border-radius: 25px;
  margin-bottom: 24px;
}

.createFlashcardIcon, .enhanceMemoryIcon {
  background: #B3C6E8;
}

.steps {
  display: flex;
  justify-content: center;
  margin-top: 68px;
}

.step {
  text-align: center;
  font-family: $normal-font-family;
  font-weight: 500;
  font-size: 18px;
  color: #2D65C2;
  text-transform: uppercase;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.stepArrowIcon {
  margin-bottom: 50px;
  margin-right: 57px;
  margin-left: 57px;
}

.reviewCardIcon {
  background: #6DD8E0;
}

.mobile {
  display: none;
}

@media screen and (max-width: $sm-max-width) {
  .stepArrowIcon {
    display: none;
  }

  .steps {
    flex-flow: column;
  }

  .step {
    margin-bottom: 50px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
