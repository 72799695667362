@import '../../variables.module.scss';

.outer {
  flex: 1;
  padding-bottom: 100px;
  background: #6DD8E0;
}

.deckIndex {
  padding: 30px 60px;
}

.placeholderImage {
  width: 62px;
}

.decks {
  display: grid;
  grid-gap: 18px;
  justify-content: flex-start;
}

.emptylist {
  font-size: 16px;
  color: #97ACD9;
  line-height: 30px;
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.header {
  display: flex;
  margin-bottom: 40px !important;
  align-items: center;
}

.spacer {
  flex: 1;
}

.plus {
  margin-right: 3px;
}

.header, .decks, .selectHeader {
  max-width: 1140px;
  margin: 0 auto;
}

.headerLink {
  font-weight: 500;
  font-size: 16px;
  color: #2D65C2;
  line-height: 30px;
  margin-right: 42px;

  &.inactive {
    color: white;
  }
}

.selectHeader {
  display: none;
  margin-bottom: 36px;
}

.selectBox {
  text-align: center;
  color: #2D65C2;
  font-size: 16px;
  font-weight: 600;
  line-height: 51px !important;
  height: 51px !important;
}

.createDeckLink {
  font-family: $title-font-family;
  font-size: 18px;
  color: #FFFFFF;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  &:hover {
    filter: brightness(90%);
  }
}

@media screen and (max-width: $sm-max-width) {
  .selectHeader {
    display: block;
  }
  .header {
    display: none;
  }

  .deckIndex {
    padding: 25px 20px;
  }
}

@media screen and (min-width: 376px) {
  .decks {
    grid-template-columns: repeat(auto-fit, 348px);
    grid-gap: 60px 48px;
  }
}

@media screen and (max-width: 1265px) {
  .header, .decks, .selectHeader {
    max-width: 744px;
  }
}

@media screen and (max-width: 797px) {
  .header, .decks, .selectHeader {
    max-width: 348px;
  }
  .decks {
    justify-content: center;
  }
}
