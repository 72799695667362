@import '../../variables.module.scss';

.topBar {
  background-color: #16B5DF;
  font-family: $title-font-family;
  font-size: 18px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 5;
}

.zh {
  font-size: 20px;
  font-weight: 600;
}

.topBarPlaceholder {
  height: 131px;
}

.icon {
  width: 60px;
  border-radius: 40px;
  border: 2px solid white;
}

.iconWrapper {
  display: flex;
  color: white;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 20px;
  font-family: $title-font-family;

  span {
    margin-left: 10px;
  }

  @media screen and (min-width: $sm-min-width) {
    span {
      display: none;
    }
  }
}

.inner {
  margin: 0 auto;
  padding: 37px 50px 34px;
  max-width: 1440px;
  display: flex;
  align-items: center;
}

.language {
  color: #3CF3EB !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: center !important;
  text-transform: uppercase;
  margin-left: 40px !important;
}

.link {
  color: white;
  margin-left: 40px;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;

  &:hover {
    filter: brightness(90%);
  }

  &.disabled {
    color: #ccc;
    pointer-events: none;
  }
}

.login {
  color: #A4ECF1;
  margin-left: 50px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }

  &:hover {
    filter: brightness(90%);
  }
}

.linkActive {
  display: block;
  position: absolute;
  width: 46px;
  bottom: -13px;
  left: 50%;
  transform: translateX(calc(-50% + 1px));
}

.searchWrapper {
  flex: 1;
}

.rightButton {
  display: none;
}

.avatarImageStyle {
  border-radius: 50%;
  border: 2px solid #67D29E;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.triangle {
  margin-right: 10px;
}

.notificationCount {
  position: absolute;
  top: -10px;
  right: -10px
}

@media screen and (max-width: $sm-max-width) {
  .link, .login, .searchWrapper {
    display: none;
  }

  .inner {
    padding: 35px 16px 10px;
    justify-content: space-between;
  }

  .icon {
    width: 42px;
    border-radius: 21px;
    border: 1px solid white;
  }

  .hiddenInMobile {
    display: none !important;
  }

  .rightButton {
    display: block;
  }

  .topBarPlaceholder {
    height: 87px;
  }
}
