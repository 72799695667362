@import '../../variables.module.scss';

.imageToSpelling {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.instruction {
  margin-top: 42px;
  margin-bottom: 31px;
  font-family: $normal-font-family;
  font-size: 14px;
}

.input {
  max-width: 100%;
  width: 348px !important;
  background: #FFFFFF !important;
  border-radius: 14px !important;
  height: 100px !important;
  font-family: $normal-font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  letter-spacing: 0.14em;
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  width: 348px;
  border: 1px solid black;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-size: cover;
}

.audio {
  position: absolute !important;
  bottom: 12px;
  right: 14px;
}

.largeAudio {
  width: 60px;
  height: 60px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.tickIconStyle {
  position:absolute;
  margin-left:280px;
  width:26px;
}

.crossIconStyle {
  position:absolute;
  margin-left:280px;
  width: 26px;
}

.correctAnsWrapper{
  font-family: $normal-font-family;
  font-style: normal;
  display:flex;
  flex-flow: row;
  text-align: center;
  font-size: 18px !important;
  margin-bottom: 20px;
  color: #389F6D;
}

.correctAns{
  font-weight: 600;
}

.correctAnsTitle{
  font-weight: 300;
}

@media screen and (max-width: $sm-max-width) {
  .instruction {
    margin-top: 21px;
    margin-bottom: 21px;
  }
}
