@import '../../variables.module.scss';

.row {
  display: flex;
  margin-bottom: 47px;
}

.wordCol {
  flex: 1;
  padding-left: 65px;
}

.word {
  font-family: $normal-font-family;
  font-weight: 500;
  font-size: 18px;
  color: #264890;
  border-bottom: 1px dashed rgba(0,0,0,0.2);
  align-self: baseline;
  padding-bottom: 4px;
}

.meaning {
  font-family: $normal-font-family;
  font-weight: 300;
  font-size: 18px;
  color: #6F7575;
  border-bottom: 1px dashed rgba(0,0,0,0.2);
  align-self: baseline;
  padding-bottom: 4px;
}

.meaningCol {
  flex: 1;
  margin-right: 76px;
}

.statusCol {
  flex-shrink: 0;
  margin-right: 65px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-self: baseline;
}

.buttonCol {
  flex-shrink: 0;
  width: 135px;
  display: flex;
  justify-content: space-between;
}

.wordMeaning {
  display: none;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}
  
@media screen and (max-width: 1430px) {
  .meaningCol {
    margin-right: 40px;
  }

  .statusCol {
    margin-right: 20px;
  }
}

@media screen and (max-width: $sm-max-width) {
  .row {
    margin-bottom: 32px;
  }

  .word {
    border-bottom: none;
    align-self: center;
  }

  .wordCol {
    padding-left: 0;
  }

  .wordTitle {
    text-decoration-line: underline;
  }

  .wordMeaning {
    display: block;
    font-family: $normal-font-family;
    font-weight: 300;
    font-size: 16px;
    color: #6F7575;
  }

  .meaningCol {
    display: none;
  }

  .statusCol {
    width: 40px;
  }

  .buttonCol {
    width: auto;
  }

  .playAudio {
    margin-right: 0;
  }

  .play {
    display: none !important;
  }
}