@import '../../variables.module.scss';

.playIndexOuter {
  flex: 1;
  background: #D7EFEE;
  padding-bottom: 100px;
}

.playIndex {
  flex: 1;
  padding: 37px 50px 34px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: $title-font-family;
  font-size: 20px;
  color: #16B5DF;
  align-self: start;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.image {
  margin-bottom: 42px;
}

.button {
  width: 700px;
  min-height: 100px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  padding: 18px 32px;
  margin-bottom: 22px;
  align-items: center;
  max-width: 100%;
  &:hover {
    border-width: 2px !important;
    filter: brightness(99%);
  }

  .icon {
    margin-right: 28px;
  }

  .nameMode {
    flex: 1;
    text-align: left;
    margin-right: 10px;
  }

  .name {
    font-family: $normal-font-family;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 5px;
  }
  
  .mode {
    font-family: $normal-font-family;
    font-weight: bold;
    font-size: 14px;
  }

  .desc {
    font-family: $normal-font-family;
    font-weight: 300;
    font-size: 12px;
    color: #6F7575;
  }

  &.auto {
    border: 1px solid #F5DF92;
    .name, .mode {
      color: #E97307;
    }
    
  }

  &.manual {
    border: 1px solid #97E8C0;
    .name, .mode {
      color: #36AD73;
    }
  }


  &.active {
    width: 800px;
  }

  &.inactive {
    min-height: 80px;
    opacity: 0.6;
    max-width: calc(100% - 40px);
  }
}

.whichDeck {
  width: 700px;
  font-family: $normal-font-family;
  font-weight: 300;
  font-size: 14px;
  color: #7C7C7C;
  margin-bottom: 10px;
  max-width: 100%;
  text-align: center;
}

.autoMode {
  font-family: $normal-font-family;
  font-weight: bold;
  font-size: 18px;
  color: #264890;
  text-align: center;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 30px;
}

.autoModeWrapper {
  max-width: 100%;
}

@media screen and (max-width: $sm-max-width) {
  .title {
    align-self: center;
    margin-bottom: 30px;
  }
  .playIndex {
    padding: 37px 12px;
  }

}

@media screen and (max-width: 400px) {
  .mode {
    display: none;
  }
}
