@import '../../variables.module.scss';

.userWrapper {
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 10px;
  width: 310px;
}

.user {
  background: #FFFFFF;
  box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: block;
}

.name {
  color: #2D65C2;
  font-size: 24px;
  font-family: $title-font-family;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.teacher {
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #6F7575;
  margin-bottom: 17px;
}

.upper {
  display: flex;
  padding: 16px 12px;
  border-bottom: 1px solid #D2D2D2;
}

.right {
  display: flex;
  flex-flow: column;
  margin-left: 16px;
}

.languages {
  display: flex;
}

.lower {
  display: flex;
  padding: 9px;
}

.stat {
  display: flex;
  flex-flow: column;
  flex: 1;
  align-items: center;

  h3 {
    color: #F79411;
    font-size: 24px;
    font-family: $title-font-family;
  }

  h4 {
    color: #97ACD9;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.swiper {
  margin-top: -10px;
}


@media screen and (max-width: $sm-max-width) {
  .swiper {
    overflow: visible;
  }
}
