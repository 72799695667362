@import '../../variables.module.scss';

.deckBox {
  height: 264px;
}

.image {
  width: 348px;
  height: 190px;
}

.placeholderImageWrapper {
  display: flex;
  justify-content: center;
  width: 348px;
  height: 190px;
  max-width: calc(100vw - 40px);
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.placeholderImage {
  width: 62px;
}

.deckBoxBottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.deckName {
  font-weight: bold;
  font-size: 16px;
  font-family: $normal-font-family;
  margin-top: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.deckBoxImageWrapper {
  display: flex;
  position: relative;
}

.lockWrapper, .linkWrapper {
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 5px 4px 4px -2px rgba(0, 0, 0, 0.3);
}

.home, .account {
  &.deckBox {
    height: 200px;
    width: 263px;
  }

  .image {
    width: 263px;
    height: 147px;
  }

  .placeholderImageWrapper {
    width: 263px;
    height: 147px;
  }
}

.followButton {
  position: absolute !important;
  top: calc(50% - 21px);
  left: calc(50% - 44px);
}

.followerCount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
  width: auto;
}

.followerNumber {
  font-family: $normal-font-family;
  font-size: 18px;
  color: #f94141;
  margin-left: 9px;
  margin-top: 4px;
}

.left {
  max-width: calc(100% - 44px);
}

.lockWrapper {
  position: relative;
}

.lockOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../images/deck-lock.svg);
  background-position: center;
  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1087px) {
  .image {
    width: 348px;
    height: 190px;
  }
}
