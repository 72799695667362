.errorMsg {
  font-family: '$normal-font-family';
  color: red;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.loginIcon{
  margin: 10px;
}

.loginIcon:hover {
  transition: .3s ease-out;
  opacity: 1;
  cursor: pointer;
  filter: invert(88%) sepia(49%) saturate(2933%) hue-rotate(155deg) brightness(94%) contrast(86%);
}

.footer {
  width: 100%;
}

.or {
  border-bottom: 0.5px solid #9FBDBC;
  margin-bottom: 25px;
  margin-top: 32px;
  span {
    margin: 0 auto;
    margin-bottom: -13px;
    display: block;
    background: white;
    font-size: 18px;
    color: #9FBDBC;
    width: fit-content;
    padding: 0 10px;
  }
}
