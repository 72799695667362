@import '../../variables.module.scss';

.slide {
  width: 100%;
  padding-bottom: 50px;
  padding: 52px 140px;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 657px;
  max-width: 603px !important;
  margin: 0 auto;
}

.swiper {
  width: 100%;

  :global {
    .swiper-pagination {
      display: none;
    }
  }
}

.modalContent {
  width: 603px !important;
  max-width: 603px !important;
}

.sliderTitle1 {
  font-family: $normal-font-family;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.24em;
  color: #6F7575;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.sliderTitle2 {
  font-family: $title-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  letter-spacing: 0.12em;
  color: #16B5DF;
  text-transform: uppercase;
}

.zoomWrapper {
  height: 186px;
  width: 186px;
  position: relative;
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
}

.zoom {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  z-index: -1;
}

.stepN {
  margin-top: 38px;
  font-family: $title-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.06em;
  color: #264890;
  text-transform: uppercase;
}

.stepText {
  font-family: $normal-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.06em;
  color: #264890;
  margin-top: 4px;
}

.stepIconText {
  font-family: $title-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #2D65C2;
  text-transform: uppercase;
  margin-top: 2px;
}

.stepDesc {
  font-family: $normal-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6F7575;
  margin-top: 28px;
  line-height: 20px;
  text-align: center;
  flex: 1;
  display: block;
}

.setup {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 130px;
  min-height: 657px;
  width: 603px !important;
  margin: 0 auto;
}

.select {
  flex: 1;
  width: 100%;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.progress {
  margin-bottom: 125px;
}

.progressText {
  font-family: $normal-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #16B5DF;
  margin-bottom: 21px;
  text-align: center;
}

@media screen and (max-width: $sm-max-width) {

  .slide {
    width: 100%;
    padding: 40px 30px 80px;
  }

  .modalContent, .modalOverlay {
    display: none !important;
  }

  .mobile {
    display: block;
    position: fixed;
    top: 85px;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 2000;
    overflow-y: scroll;
    padding-bottom: 20px;
  }

  .swiper {
    width: 100%;

    :global {
      .swiper-pagination {
        display: block;
      }
    }
  }

  .next {
    display: none !important;
  }

  .setup {
    padding: 90px 15px;
    max-width: 100% !important;
  }
}
